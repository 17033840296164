export default [
  {
    title: "序号",
    dataIndex: "index",
    customRender: (value, item, index) => {
      return index + 1;
    },
    width: 60,
  },
  {
    title: "编号",
    dataIndex: "number",
    sorter: true,
  },
  {
    title: "名称",
    dataIndex: "name",
    sorter: true,
  },
  {
    title: "规格",
    dataIndex: "spec",
  },
  {
    title: "单位",
    dataIndex: "unit",
  },
  {
    title: "条码",
    dataIndex: "barcode",
  },
  {
    title: "分类",
    dataIndex: "category",
    customRender: (value, item) => {
      return item.category_items.map((item) => item.name).join(", ");
    },
  },
  {
    title: "客户",
    dataIndex: "client_name",
    customRender: (value, item) => {
      return item.client_item.name;
    },
  },
  {
    title: "状态",
    dataIndex: "is_active",
    scopedSlots: { customRender: "is_active" },
  },
  {
    title: "备注",
    dataIndex: "remark",
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "220px",
  },
];
