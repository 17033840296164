import { getLodop } from "@/assets/LodopFuncs";
import JsBarcode from "jsbarcode";

export default function printLabel(items, labelSize) {
  const LODOP = getLodop();
  LODOP.PRINT_INIT("Location Label");
  LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW", true);
  LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);

  if (labelSize === "length_95_width_43") {
    LODOP.SET_PRINT_PAGESIZE(1, "95mm", "43mm");
  }

  for (const item of items) {
    print_length_95_width_43_label(LODOP, item);
  }

  LODOP.PREVIEW();
}

function print_length_95_width_43_label(LODOP, item) {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, item.number, {
    format: "CODE128",
    width: 3,
    height: 30,
    displayValue: true,
    background: "#ffffff",
    lineColor: "#000000",
  });
  const barcodeBase64 = canvas.toDataURL();
  const htmlText = `
<div style="padding: 1mm;">
  <table border="1" cellspacing="0" style="font-size: 14px; width: 100%; height: 100%; text-align: center">
    <tr>
      <td colspan="4">光大环保能源 (海盐) 有限公司</td>
    </tr>
    <tr>
      <td style="width: 10mm">
        <div>名称</div>
      </td>
      <td style="width: 55mm">${item.name ?? ""}</td>
      <td style="width: 10mm">
        单位
      </td>
      <td>${item.unit ?? ""}</td>
    </tr>
    <tr>
      <td style="width: 10mm">
        <div>规格</div>
      </td>
      <td colspan="3">${item.spec ?? ""}</td>
    </tr>
    <tr>
      <td colspan="4">
        <img src="${barcodeBase64}" />
      </td>
    </tr>
  </table>
</div>`;

  LODOP.NewPage();
  LODOP.ADD_PRINT_HTM("0%", "0%", "100%", "100%", htmlText);
}
