import request from "@/utils/request";

// Material
export function materialList(params) {
  return request({ url: `/materials/`, method: "get", params });
}

export function materialCreate(data) {
  return request({ url: `/materials/`, method: "post", data });
}

export function materialUpdate(data) {
  return request({ url: `/materials/${data.id}/`, method: "put", data });
}

export function materialDestroy(data) {
  return request({ url: `/materials/${data.id}/`, method: "delete", data });
}

export function materialNumber(params) {
  return request({ url: `/materials/number/`, method: "get", params });
}

export function materialsTemplate(params) {
  return request({
    url: "/materials/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function materialsImport(data) {
  return request({
    url: "/materials/import_data/",
    method: "post",
    data,
  });
}

export function materialsExport(params) {
  return request({
    url: "/materials/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}
